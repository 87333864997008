.cart-items td,
.cart-items th {
  padding: 0;
  border: none;
}

.cart-items th {
  text-align: left;
  padding-bottom: 1.8rem;
  opacity: 0.85;
  font-weight: normal;
}

.cart-item__quantity-wrapper {
  display: flex;
}

.cart-item__totals {
  position: relative;
}

.cart-items *.right {
  text-align: right;
}

.cart-item__image-container {
  display: inline-flex;
  align-items: flex-start;
}

.cart-item__image-container:after {
  content: none;
}

.cart-item__image {
  height: auto;
  max-width: calc(10rem / var(--font-body-scale));
}

@media screen and (min-width: 750px) {
  .cart-item__image {
    max-width: 100%;
  }
}

.cart-item__details {
  font-size: 1.6rem;
  line-height: calc(1 + 0.4 / var(--font-body-scale));
}

.cart-item__details > * {
  margin: 0;
  max-width: 30rem;
}

.cart-item__details > * + * {
  margin-top: 0.6rem;
}

.cart-item__media {
  position: relative;
}

.cart-item__link {
  display: block;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.cart-item__name {
  text-decoration: none;
  display: block;
}

.cart-item__name:hover {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.2rem;
}

.cart-item__price-wrapper > * {
  display: block;
  margin: 0;
  padding: 0;
}

.cart-item__discounted-prices dd {
  margin: 0;
}

.cart-item__discounted-prices .cart-item__old-price {
  font-size: 1.4rem;
}

.cart-item__old-price {
  opacity: 0.7;
}

.cart-item__final-price {
  font-weight: 400;
}

.product-option {
  font-size: 1.4rem;
  word-break: break-all;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
}

.cart-item cart-remove-button {
  display: flex;
  margin-left: 1rem;
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .cart-item cart-remove-button {
    width: 4.5rem;
    height: 4.5rem;
  }
}

cart-remove-button .button {
  min-width: calc(4.5rem / var(--font-body-scale));
  min-height: 4.5rem;
  padding: 0;
  margin: 0 0.1rem 0.1rem 0;
}

cart-remove-button .button:before,
cart-remove-button .button:after  {
  content: none;
}

cart-remove-button .button:not([disabled]):hover {
  color: rgb(var(--color-foreground));
}

@media screen and (min-width: 750px) {
  cart-remove-button .button {
    min-width: 3.5rem;
    min-height: 3.5rem;
  }
}

cart-remove-button .icon-remove {
  height: 1.5rem;
  width: 1.5rem;
}

.cart-item .loading-overlay {
  top: 0;
  left: auto;
  right: auto;
  bottom: 0;
  padding: 0;
}

@media screen and (min-width: 750px) {
  .cart-item .loading-overlay {
    right: 0;
    padding-top: 4.5rem;
    bottom: auto;
  }
}

.cart-item .loading-overlay:not(.hidden) ~ * {
  visibility: hidden;
}

.cart-item__error {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

.cart-item__error-text {
  font-size: 1.2rem;
  order: 1;
}

.cart-item__error-text + svg {
  flex-shrink: 0;
  width: 1.2rem;
  margin-right: 0.7rem;
}

.cart-item__error-text:empty + svg {
  display: none;
}

.product-option + .product-option {
  margin-top: 0.4rem;
}

.product-option * {
  display: inline;
  margin: 0;
}

.cart-items thead th {
  text-transform: uppercase;
}

@media screen and (max-width: 749px) {
  .cart-items,
  .cart-items thead,
  .cart-items tbody {
    display: block;
    width: 100%;
  }

  .cart-items thead tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.2);
    margin-bottom: 4rem;
  }

  .cart-item {
    display: grid;
    grid-template: repeat(2, auto) / repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 3.5rem;
  }

  .cart-item:last-child {
    margin-bottom: 0;
  }

  .cart-item__media {
    grid-row: 1 / 3;
  }

  .cart-item__details {
    grid-column: 2 / 4;
  }

  .cart-item__quantity {
    grid-column: 2 / 5;
  }

  .cart-item__quantity-wrapper {
    flex-wrap: wrap;
  }

  .cart-item__totals {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

.cart-item__error-text + svg {
  margin-top: 0.4rem;
}

@media screen and (min-width: 750px) {
  .cart-items {
    border-spacing: 0;
    border-collapse: separate;
    box-shadow: none;
    width: 100%;
    display: table;
  }

  .cart-items th {
    border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  }

  .cart-items thead th:first-child {
    width: 50%;
  }

  .cart-items th + th {
    padding-left: 4rem;
  }

  .cart-items td {
    vertical-align: top;
    padding-top: 4rem;
  }

  .cart-item {
    display: table-row;
  }

  .cart-item > td + td {
    padding-left: 4rem;
  }

  .cart-item__details {
    width: 35rem;
  }

  .cart-item__media {
    width: 10rem;
  }

  .cart-item cart-remove-button {
    margin: 0.5rem 0 0 1.5rem;
  }

  .cart-item__price-wrapper > *:only-child:not(.cart-item__discounted-prices) {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 990px) {
  .cart-item .cart-item__quantity,
  .cart-items .cart-items__heading--wide {
    padding-left: 6rem;
  }

  .cart-item__details {
    width: 50rem;
  }

  .cart-items thead th:first-child {
    width: 60%;
  }
}
.cart-notification-wrapper {
  position: relative;
}

.cart-notification-wrapper .cart-notification {
  display: block;
}

.cart-notification {
  border-bottom-right-radius: var(--popup-corner-radius);
  border-bottom-left-radius: var(--popup-corner-radius);
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-style: solid;
  border-width: 0 0 var(--popup-border-width);
  padding: 2.5rem 3.5rem;
  position: absolute;
  right: 0;
  transform: translateY(-100%);
  visibility: hidden;
  width: 100%;
  z-index: -1;
  filter: drop-shadow(
    var(--popup-shadow-horizontal-offset)
    var(--popup-shadow-vertical-offset)
    var(--popup-shadow-blur-radius)
    rgba(var(--color-shadow), var(--popup-shadow-opacity))
  );
}

@media screen and (min-width: 750px) {
  .header-wrapper:not(.header-wrapper--border-bottom) + cart-notification .cart-notification {
    border-top-width: var(--popup-border-width);
  }

  .cart-notification {
    border-width: 0 var(--popup-border-width) var(--popup-border-width);
    max-width: 36.8rem;
    right: 4rem;
  }
}

.cart-notification.animate {
  transition: transform var(--duration-short) ease,
    visibility 0s var(--duration-short) ease;
}

.cart-notification.active {
  transform: translateY(0);
  transition: transform var(--duration-default) ease, visibility 0s;
  visibility: visible;
}

.cart-notification__header {
  align-items: flex-start;
  display: flex;
}

.cart-notification__heading {
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin-bottom: 0;
  margin-top: 0;
}

.cart-notification__heading .icon-checkmark {
  color: rgb(var(--color-foreground));
  margin-right: 1rem;
  width: 1.3rem;
}

.cart-notification__close {
  margin-top: -2rem;
  margin-right: -3rem;
}

.cart-notification__links {
  text-align: center;
}

.cart-notification__links > * {
  margin-top: 1rem;
}

.cart-notification-product {
  align-items: flex-start;
  display: flex;
  padding-bottom: 3rem;
  padding-top: 2rem;
}

.cart-notification-product dl {
  margin-bottom: 0;
  margin-top: 0;
}

.cart-notification-product__image {
  display: inline-flex;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
}

.cart-notification-product__image:after {
  content: none;
}

.cart-notification-product__name {
  margin-bottom: 0.5rem;
  margin-top: 0;
}
